import Sortable from '@stimulus-components/sortable'
// See https://www.stimulus-components.com/docs/stimulus-sortable

export default class extends Sortable {
  connect() {
    super.connect()
  }

  // Parent method calls this.sortable.destroy() before this.sortable = undefined
  // This prevents sortable from reconnecting if it is expanded via collapsible controller
  disconnect() {
    this.sortable = undefined
  }

  // Override the `onUpdate` method to add a `saving` class to the item
  onUpdate(event) {
    const item = event.item;
    item.classList.add('saving');

    const savingEvent = new CustomEvent('saving', { detail: { item } });
    window.dispatchEvent(savingEvent);

    super.onUpdate(event).then(() => {
      item.classList.remove('saving');

      const savedEvent = new CustomEvent('saved', { detail: { item } });
      window.dispatchEvent(savedEvent);
    });
  }
}
